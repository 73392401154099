import React, {FC} from "react";
import cls from "./builder.module.scss"
import {TranslationsType} from "../../types/homePageTypes";
import {builderDataType} from "./types";
import RenderBuilderComponent from "./RenderCustomElements";
import {ICurrencies} from "../ForCWV/Wrappers/MainLayout";

interface IProps {
    getBuilderData: builderDataType
    tab: number
    domain: string;
    dbName: string
    translationsData: TranslationsType,
    selectedLocale: string,
    backOrderValue: string,
    currencies: Array<ICurrencies>;
}

const BuilderSection: FC<IProps> = ({getBuilderData: clientShowMap, tab, ...builderComponentProps}) => {
    const BuilderJSX = ({dataKey}: any) => <RenderBuilderComponent data={dataKey} {...builderComponentProps}/>
    return (
        <div className="block-products">
            <div className={`${cls["home-product-container"]} ${cls["b-container"]}`}>
                {clientShowMap[tab]?.map((
                    {
                        idArray,
                        parentCls,
                        container,
                        rowStyles,
                        childWrapper,
                        parentAlignItems,
                        showOneComponent
                    }, ind: number) => {
                    const handleComponentShow = {
                        "true": <BuilderJSX dataKey={showOneComponent}/>,
                        "false": idArray?.map((
                            {
                                childIdArray,
                                showOneComponent: showOneComponentChild,
                                childCls,
                                secondChildCls,
                                columnStyles
                            }, index: number) => {
                            const handleComponentShowChild = {
                                "true": <BuilderJSX dataKey={showOneComponentChild}/>,
                                "false": childIdArray?.map((elInEl, indElInEl: number) => (
                                    <div key={indElInEl} className={cls.builder_component}>
                                        <BuilderJSX dataKey={elInEl}/>
                                    </div>
                                ))
                            }
                            return <div key={index} className={`${childCls} ${cls[secondChildCls]}`}
                                        style={columnStyles}>
                                {handleComponentShowChild[`${!!showOneComponentChild}`]}
                            </div>
                        })
                    }
                    return <div key={ind} className={`${container} ${cls.builder_component}`}>
                        <div className={parentCls} style={rowStyles}>
                            <div style={{...childWrapper, alignItems: parentAlignItems, width: "100%"}}>
                                {handleComponentShow[`${!!showOneComponent}`]}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default BuilderSection
